import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

type Props = {
  /** small link text above title (eg "< Back to Blogs")*/
  link?: {
    text: string;
    url: string;
  };
  subtitle?: string;
  /** the title text */
  children: React.ReactNode;
};

/**
 * h1 Centred Page Title with optional link above it (eg "< Back to Blogs") and a subtitle.
 *
 * @param root0
 * @param root0.children
 * @param root0.link
 * @param root0.subtitle
 */
export const PageTitle: React.FC<Props> = ({ children, link, subtitle }) => (
  <Centered>
    {link && <HeaderLink to={link.url}>{link.text}</HeaderLink>}
    <h1>{children}</h1>
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
  </Centered>
);

const Centered = styled.div`
  text-align: center;
  margin-top: 30px;
  padding-left: 32px;
  padding-right: 32px;
`;

const HeaderLink = styled(Link)`
  color: inherit;
  display: block;
  margin-bottom: 20px;
  text-decoration: underline;
`;

const Subtitle = styled.span`
  display: block;
  font-style: italic;
  margin-top: -30px;
`;
