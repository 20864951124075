import { graphql, PageProps } from 'gatsby';
import React from 'react';
import { BlogPostListing } from '../components/BlogPostListing';
import { Page } from '../components/Page';
import { PageTitle } from '../components/PageTitle';
import { SectionContainer, SectionContent } from '../components/section';
import { BlogListingQuery } from '../generated/graphqlTypes';

export type Props = Pick<PageProps<BlogListingQuery>, 'data'>;

const Blog: React.FC<Props> = ({ data }) => (
  <Page>
    <PageTitle>Upside Blog</PageTitle>
    <SectionContainer>
      <SectionContent isSmallMaxWidth={true}>
        {data.allDatoCmsBlog.edges.length > 0 ? (
          data.allDatoCmsBlog.edges.map((edge, index) => (
            <BlogPostListing key={edge.node.slug} post={edge.node} isFeatured={index === 0} />
          ))
        ) : (
          <p>No blog posts.</p>
        )}
      </SectionContent>
    </SectionContainer>
  </Page>
);

export default Blog;

/**
 * Get blog posts
 *
 * TODO: pagination on blog listings page?
 */
export const query = graphql`
  query BlogListing {
    allDatoCmsBlog(sort: { fields: publishedDate, order: DESC }, filter: { destination: { in: ["WEB", "ALL"] } }) {
      edges {
        node {
          id
          title
          slug
          excerpt
          publishedDate
          coverImage {
            fluid(maxWidth: 910, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsSizes
            }
          }
        }
      }
    }
  }
`;
