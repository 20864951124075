import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import styled, { css } from 'styled-components';
import { getBlogLink } from '../../constants/urls';
import { DatoCmsBlog } from '../../generated/graphqlTypes';
import { formatDate } from '../../helpers/date';
import { fixFluid } from '../../helpers/image';
import { breakpoints } from '../../styles/breakpoints';
import { colors } from '../../styles/colors';
import { palette } from '../../styles/palette';
import typographyPlugin from '../../styles/typographyPlugin';

type Props = {
  post: Pick<DatoCmsBlog, 'publishedDate' | 'title' | 'slug' | 'coverImage' | 'excerpt'>;
  isFeatured: boolean;
};

export const BlogPostListing: React.FC<Props> = ({ post, isFeatured }) => {
  const url = getBlogLink(post.slug);
  const fluid = fixFluid(post.coverImage?.fluid);

  const renderThumbnail = () => (
    <Link to={url}>
      <ThumbnailWrapper>{fluid && <Img fluid={fluid} />}</ThumbnailWrapper>
    </Link>
  );

  const renderInfo = () => (
    <>
      <TitleLink to={url}>
        <TitleHeader>{post.title}</TitleHeader>
      </TitleLink>
      <PublishedDate>Published on {formatDate(post.publishedDate)}</PublishedDate>
      <p>{post.excerpt}</p>
      <ReadMoreLink to={url}>Read more...</ReadMoreLink>
    </>
  );

  if (isFeatured) {
    return (
      <FeaturedWrapper>
        {renderThumbnail()}
        {renderInfo()}
      </FeaturedWrapper>
    );
  }

  return (
    <NormalWrapper>
      {fluid && <div className="thumbnail">{renderThumbnail()}</div>}
      <div className="info">{renderInfo()}</div>
    </NormalWrapper>
  );
};

const wrapperStyle = css`
  border-bottom: 1px solid ${palette.lightGrey};
  margin-bottom: 35px;
  padding-bottom: 45px;

  &:last-of-type {
    /** no need for margin on last one */
    margin-bottom: 0;
  }
`;

const FeaturedWrapper = styled.article`
  ${wrapperStyle}
`;

const NormalWrapper = styled.article`
  ${wrapperStyle}
  display: flex;

  /** thumbnail above in a column on mobile */
  flex-direction: column;

  .thumbnail {
    flex: 1;
    padding-right: 20px;
  }

  .info {
    flex: 3;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    /** thumbnail to left on desktop */
    flex-direction: row;
  }
`;

const TitleLink = styled(Link)`
  color: ${colors.text.default};
  text-decoration: none;
`;

const ReadMoreLink = styled(Link)`
  font-family: ${typographyPlugin.options.headerFontFamily?.join(',')};
`;

const TitleHeader = styled.h2`
  margin-bottom: 0.3rem;
  margin-top: 0.6rem;
`;

const ThumbnailWrapper = styled.div`
  margin-top: 10px; /* Vertically align thumbnail with title */

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-bottom: 12px;
  }
`;

const PublishedDate = styled.p`
  font-style: italic;
  font-size: 0.7rem;
  margin-bottom: 1.4rem;
`;
